import React, { useEffect, useState } from "react";

// API
import {
  GetDeviceData,
  DeleteDeviceAPI,
  GetDeviceHourlyReportData,
  GetDevices,
  GetDeviceDailyReportData,
  GetLastDayReportData,
  GetDeviceHourReportData,
} from "../../Service/DeviceAPI";
import { useLocation, useNavigate } from "react-router-dom";

// Helper
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiInformationLine } from "react-icons/ri";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

// Styling
import "./Device.scss";

// Other Components
import { useSelector } from "react-redux";
import { Button, Input, Spin } from "antd";
import ROLES from "../../Utils/Roles";
import ConfirmationModel from "../Common/ConfirmationModel/ConfirmationModel";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";
import { DateRangePicker } from "rsuite";
import { GetEmail, addEmail } from "../../Service/Email";
import AreaChart1 from "../Common/Charts/AreaChart1/AreaChart1";
import { convertMinutesToHoursMinutes } from "../../Utils/convertToHourAndMint";
import AvailableFuelChart from "../Common/Charts/AreaChart1/AvailableFuelChart";

// ------------------------------------------Start Function------------------------

export default function Device() {
  // Searching the data from start to end date------------
  const [value, setValue] = useState([]);

  let new1 = moment(value[0])?.format("DD-MM-YYYY");
  let new2 = moment(value[1])?.format("DD-MM-YYYY");

  let sdate = moment(value[0])?.format("YYYY-MM-DD");
  let edate = moment(value[1])?.format("YYYY-MM-DD");

  let sendDate = {
    startDate: new1,
    endDate: new2,
  };
  let dateback = {
    startDate: sdate,
    endDate: edate,
  };
  const settingDate = (event) => {
    if (event == null) {
      setValue([]);
    } else {
      setValue(event);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [lastDayData, setLastDayData] = useState([]);
  const [lastDayReport, setLastDayReport] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    deviceId: null,
    loading: false,
  });

  const dataLastDay = lastDayReport[0];
  const latestValue = dataLastDay?.[dataLastDay?.length - 1];

  let countFuelConsumed =
    lastDayData[lastDayData?.length - 1]?.totalfuelconsumed -
    lastDayData?.[0]?.totalfuelconsumed;
  let countRefueling =
    lastDayData[lastDayData?.length - 1]?.totalrefueling -
      lastDayData?.[0]?.totalrefueling >=
    0
      ? lastDayData[lastDayData?.length - 1]?.totalrefueling -
        lastDayData?.[0]?.totalrefueling
      : lastDayData[lastDayData?.length - 1]?.totalrefueling;
  let countPilferage =
    lastDayData[lastDayData?.length - 1]?.totalpilferage -
    lastDayData?.[0]?.totalpilferage;
  let countGenSetRunTime =
    lastDayData[lastDayData?.length - 1]?.totalGensetRunTime -
    lastDayData?.[0]?.totalGensetRunTime;

  const genSetRunInHour = countGenSetRunTime / 60;
  let averageFuel = countFuelConsumed / genSetRunInHour;

  const firstDevice = deviceData[0];
  const machine = location?.state?.machine;
  const buildingId = location?.state?.buildingId;
  const departmentId = location?.state?.departmentId;
  const buildingName = location?.state?.buildingName;
  const departmentName = location?.state?.departmentName;

  let machineId = machine?._id;

  const gettingDeviceData = async () => {
    setLoading(true);
    let payload = {
      machineId: machineId,
    };
    let res = await GetDeviceData(payload);
    if (res?.error != null) {
      toast.error(res?.error);
    } else {
      setDeviceData(res?.data?.result);
      if (res?.data?.result[0]) {
        const deviceData = res?.data?.result[0];
        const latestDate = deviceData[deviceData?.length - 1]?.datetime;
        if (latestDate) {
          const lastDate = new Date(latestDate);
          lastDate.setDate(lastDate.getDate() - 1);
          lastDate.setUTCHours(0, 0, 0, 0);
          let payload = {
            machineId: machineId,
            startDate: new Date(lastDate),
            endDate: new Date(lastDate),
          };

          let res = await GetDeviceHourReportData(payload);
          if (res?.error != null) {
            setLoading(false);
            toast.error(res.error);
          } else {
            setLastDayData(res?.data?.result);
            return true;
          }
        }
      }
    }
    setLoading(false);
  };
  const gettingDevices = async () => {
    setLoading(true);
    let payload = {
      machineId: machineId,
    };
    let res = await GetDevices(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      setDevices(res?.data?.result);
    }
    setLoading(false);
  };

  const gettingLastDay = async () => {
    setLoading(true);
    let payload = {
      machineId: machineId,
    };
    let res = await GetLastDayReportData(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      setLastDayReport(res?.data?.result);
    }
    setLoading(false);
  };

  useEffect(() => {
    gettingDeviceData();
    gettingDevices();
    gettingLastDay();
  }, [refreshPage]);

  // Getting User Data
  const userData = useSelector((state) => state?.userData);

  const handleAddDevice = () => {
    navigate("/device/add", {
      state: {
        machineId: machineId,
        buildingId: buildingId,
        departmentId: departmentId,
      },
    });
  };

  const handleDeleteDeviceConfirmation = (device) => {
    setDeleteConfirmation({
      open: true,
      deviceId: device?._id,
      loading: false,
    });
  };
  //   Delete Device Handle
  const handleDeleteDevice = async (device) => {
    setDeleteConfirmation({
      ...deleteConfirmation,
      loading: true,
    });
    const res = await DeleteDeviceAPI(deleteConfirmation?.deviceId);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      toast.success(res?.data?.message);
      setRefreshPage(!refreshPage);
    }
    setDeleteConfirmation({
      open: false,
      deviceId: null,
      loading: false,
    });
  };
  const handleNotDeleteDevice = () => {
    setDeleteConfirmation({
      open: false,
      deviceId: null,
      loading: false,
    });
  };

  // the function is use to find the date between start date and last date
  const findByDate = async () => {
    setLoading(true);
    let payload = {
      machineId: machineId,
      startDate: dateback?.startDate,
      endDate: dateback?.endDate,
    };
    let res = await GetDeviceHourReportData(payload);
    if (res?.error != null) {
      setLoading(false);
      toast.error(res.error);
    } else {
      navigate("/dev/report", {
        state: {
          date: sendDate,
          filterDate: res?.data?.result,
          machineName: machine?.title,
          buildingName: buildingName,
          departmentName: departmentName,
        },
      });
    }

    setLoading(false);
  };

  return (
    <>
      {loading && (
        <>
          <Spin size="large" fullscreen={true} />
        </>
      )}
      <div className="MainDeviceWithFooter">
        <div className="deviceMainContainer">
          <div className="deviceMain">
            <div className="deviceHeader">
              <div className="headleft">
                <BsArrowLeftShort
                  className="icon cursor"
                  onClick={() => navigate(-1)}
                />
                <div className="heading">{`${buildingName}>${departmentName}>${machine?.title}(${devices[0]?.Device_ID})`}</div>
              </div>
              <div className="part2">
                <div className="datepiker">
                  <DateRangePicker
                    showOneCalendar
                    className="rangepiker"
                    onChange={(event) => settingDate(event)}
                    value={value}
                    placeholder="Start Date ~ End Date"
                    renderValue={(value) => {
                      return (
                        moment(value[0])?.format("DD-MM-YYYY") +
                        " ~ " +
                        moment(value[1])?.format("DD-MM-YYYY")
                      );
                    }}
                  />
                  {deviceData.length > 0 ? (
                    <Button
                      className="btnreport EditBtn"
                      type="submit"
                      onClick={findByDate}
                    >
                      {" "}
                      Report
                    </Button>
                  ) : (
                    <Button
                      disabled
                      className="btnreport EditBtn"
                      type="submit"
                      onClick={findByDate}
                    >
                      {" "}
                      Report
                    </Button>
                  )}
                </div>
                {[ROLES.Admin, ROLES.SuperAdmin].includes(userData?.role) && (
                  <>
                    {deviceData?.length < 1 ? (
                      <Button className="EditBtn" onClick={handleAddDevice}>
                        Add Device
                      </Button>
                    ) : (
                      <Button
                        className="EditBtn"
                        onClick={() =>
                          handleDeleteDeviceConfirmation(devices[0])
                        }
                      >
                        Delete Device
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            {deviceData.length > 0 ? (
              <div className="deviceData">
                <div className="tablesMain">
                  <div className="detailPart1 deviceDetails">
                    <div className="tables">
                      <div className="tablePart2">
                        <table>
                          <tr className="headerRow">
                            <th colSpan={2} className="tableh">
                              FUEL
                            </th>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Available Fuel Ltr</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.availablefuel
                                ? parseFloat(
                                    firstDevice?.[firstDevice?.length - 1]
                                      ?.availablefuel
                                  ).toFixed(1)
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Fuel Level %</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]?.fuellevel
                                ? firstDevice?.[firstDevice?.length - 1]
                                    ?.fuellevel
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Tank Volume Ltr</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.tankvolume
                                ? firstDevice?.[firstDevice?.length - 1]
                                    ?.tankvolume
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Fuel Temp °C</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]?.fuelTemp
                                ? firstDevice?.[firstDevice?.length - 1]
                                    ?.fuelTemp
                                : 0}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">
                              Total Fuel Consumption Ltr
                            </td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.totalfuelconsumed
                                ? parseFloat(
                                    firstDevice?.[firstDevice?.length - 1]
                                      ?.totalfuelconsumed
                                  ).toFixed(1)
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Total Fuel Fill Ltr</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.totalrefueling
                                ? parseFloat(
                                    firstDevice?.[firstDevice?.length - 1]
                                      ?.totalrefueling
                                  ).toFixed(1)
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">
                              Total Fuel Pilferage Ltr
                            </td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.totalpilferage
                                ? parseFloat(
                                    firstDevice?.[firstDevice?.length - 1]
                                      ?.totalpilferage
                                  ).toFixed(1)
                                : "-"}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="tablePart1">
                        <table>
                          <tr className="headerRow">
                            <th colSpan={2} className="tableh">
                              STATUS
                            </th>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Updated Date</td>
                            <td className="bodyDetail bodyValues">
                              {moment(
                                firstDevice?.[firstDevice?.length - 1]?.datetime
                              ).format("DD-MM-YYYY")}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Updated Time</td>
                            <td className="bodyDetail bodyValues">
                              {moment(
                                firstDevice?.[firstDevice?.length - 1]?.datetime
                              ).format("HH:mm")}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Generator Status</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.Gen_Status
                                ? firstDevice?.[firstDevice?.length - 1]
                                    ?.Gen_Status === "1"
                                  ? "ON"
                                  : "OFF"
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">WAPDA Status</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.wapdaStatus
                                ? firstDevice?.[firstDevice?.length - 1]
                                    ?.wapdaStatus === "1"
                                  ? "ON"
                                  : "OFF"
                                : "-"}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    {/* <div className="areaChartSteamFlow">
                    <AreaChart
                      labels={firstDevice?.map((time) =>
                        moment(time?.datetime).format("DD |HH:mm")
                      )}
                      genData={firstDevice?.map((data) =>
                        parseInt(data?.Gen_Status)
                      )}
                      labelname2={"Genset Status"}
                    />
                  </div> */}
                  </div>
                  <div className="detailPart2 deviceDetails">
                    <div className="tables">
                      <div className="tablePart1">
                        <table>
                          <tr className="headerRow">
                            <th colSpan={2} className="tableh">
                              LAST ACTIVITY
                            </th>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Fuel Consumed Ltr</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.fuelconsumed
                                ? parseFloat(
                                    firstDevice?.[firstDevice?.length - 1]
                                      ?.fuelconsumed
                                  ).toFixed(1)
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Fuel Fill Ltr</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]?.refueling
                                ? parseFloat(
                                    firstDevice?.[firstDevice?.length - 1]
                                      ?.refueling
                                  ).toFixed(1)
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Fuel Pilferage Ltr</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]?.pilferage
                                ? parseFloat(
                                    firstDevice?.[firstDevice?.length - 1]
                                      ?.pilferage
                                  ).toFixed(1)
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">GENSET Start Time</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.gensetOnTime
                                ? (firstDevice?.[
                                    firstDevice?.length - 1
                                  ]?.gensetOnTime).slice(0, 5)
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">GENSET Stop Time</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.gensetOffTime
                                ? (firstDevice?.[
                                    firstDevice?.length - 1
                                  ]?.gensetOffTime).slice(0, 5)
                                : "-"}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">GENSET Run Time M</td>
                            <td className="bodyDetail bodyValues">
                              {firstDevice?.[firstDevice?.length - 1]
                                ?.gensetruntime
                                ? convertMinutesToHoursMinutes(
                                    firstDevice?.[firstDevice?.length - 1]
                                      ?.gensetruntime
                                  )
                                : "-"}
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="tablePart3">
                        <table>
                          <tr className="headerRow">
                            <th colSpan={2} className="tableh">
                              DAILY ACTIVITY
                            </th>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Fuel Consumed Ltr</td>
                            <td className="bodyDetail bodyValues">
                              {countFuelConsumed
                                ? parseFloat(countFuelConsumed).toFixed(1)
                                : 0.0}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Fuel Fill Ltr</td>
                            <td className="bodyDetail bodyValues">
                              {countRefueling
                                ? parseFloat(countRefueling).toFixed(1)
                                : 0.0}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Fuel Pilferage Ltr</td>
                            <td className="bodyDetail bodyValues">
                              {countPilferage
                                ? parseFloat(countPilferage).toFixed(1)
                                : 0.0}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">
                              Total Genset Run Time M
                            </td>
                            <td className="bodyDetail bodyValues">
                              {countGenSetRunTime ? countGenSetRunTime : 0}
                            </td>
                          </tr>
                          <tr className="bodyRow">
                            <td className="bodyDetail">Fuel Average Ltr/h</td>
                            <td className="bodyDetail bodyValues">
                              {averageFuel
                                ? countGenSetRunTime !== 0
                                  ? parseFloat(averageFuel).toFixed(1)
                                  : 0
                                : 0}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="charts">
                  <div className="areaChartSteamFlow">
                    <AvailableFuelChart
                      labels={firstDevice?.map((time) =>
                        moment(time?.datetime).format("DD |HH:mm")
                      )}
                      availableFuel={firstDevice?.map((data) =>
                        parseFloat(data?.availablefuel).toFixed(1)
                      )}
                      labelname1={"Available Fuel"}
                    />
                  </div>
                  <div className="areaChartSteamFlow">
                    <AreaChart1
                      labels={firstDevice?.map((time) =>
                        moment(time?.datetime).format("DD |HH:mm")
                      )}
                      fuelFill={firstDevice?.map((data) =>
                        parseFloat(data?.refueling).toFixed(1)
                      )}
                      labelname1={"Fuel Fill"}
                      fuelConsumed={firstDevice?.map((data) =>
                        parseFloat(data?.fuelconsumed).toFixed(1)
                      )}
                      labelname2={"Fuel Consumed"}
                      pilferage={firstDevice?.map((data) =>
                        parseFloat(data?.pilferage).toFixed(1)
                      )}
                      labelname3={"Pilferage"}
                    />
                  </div>

                  <div className="areaChartSteamFlow">
                    <AreaChart
                      labels={firstDevice?.map((time) =>
                        moment(time?.datetime).format("DD |HH:mm")
                      )}
                      genData={firstDevice?.map((data) =>
                        parseInt(data?.Gen_Status)
                      )}
                      labelname2={"Genset Status"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="noValue">No Data Found</div>
            )}
          </div>
        </div>
      </div>

      <ConfirmationModel
        open={deleteConfirmation.open}
        onOk={handleDeleteDevice}
        onCancel={handleNotDeleteDevice}
        confirmLoading={deleteConfirmation.loading}
        test={deleteConfirmation?.deviceId}
      >
        <div className="deleteModel">
          <div className="titleBox">
            <RiInformationLine className="icon" />{" "}
            <div className="title">
              {" "}
              Are you sure you want to delete this Device?{" "}
            </div>
          </div>
        </div>
      </ConfirmationModel>
    </>
  );
}
