import React, { useState } from "react";
import "./ReportMachine.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";
import moment from "moment";
import { GetDeviceHourlyReportData } from "../../Service/DeviceAPI";
import { toast } from "react-toastify";
import AreaChart1 from "../Common/Charts/AreaChart1/AreaChart1";
import AreaChart2 from "../Common/Charts/AreaChart1/AreaChart2";
import { Spin } from "antd";
import idcLogo from "../../Asserts/Logo/idc.png";
import { convertMinutesToHoursMinutes } from "../../Utils/convertToHourAndMint";
import AvailableFuelChart from "../Common/Charts/AreaChart1/AvailableFuelChart";

const ReportMachine = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const date = location?.state?.date;
  const machineName = location?.state?.machineName;
  const buildingName = location?.state?.buildingName;
  const departmentName = location?.state?.departmentName;
  let ar = location?.state?.filterDate;

  const groupedData = ar?.reduce((acc, curr) => {
    const datetime = new Date(curr.datetime);
    datetime.setHours(datetime.getHours() + 5); // Adjusting to Pakistan Time

    // Format the date
    const date = datetime.toISOString().split("T")[0];
    if (!acc[date]) acc[date] = [];
    acc[date].push(curr);
    return acc;
  }, {});

  const array = Object.keys(groupedData).map((date) => {
    const dayData = groupedData[date].sort(
      (a, b) => new Date(a.datetime) - new Date(b.datetime)
    );
    // total fuel consumed functionality
    const firsttotalfuelconsumed = dayData[0]?.totalfuelconsumed;
    const secondtotalfuelconsumed =
      dayData[dayData.length - 1]?.totalfuelconsumed;
    const totalfuelconsumed =
      secondtotalfuelconsumed - firsttotalfuelconsumed >= 0
        ? secondtotalfuelconsumed - firsttotalfuelconsumed
        : secondtotalfuelconsumed;

    const availableFuel = dayData[dayData?.length - 1]?.availablefuel;

    // total refueling consumed functionality
    const firsttotalrefueling = dayData[0]?.totalrefueling;
    const secondtotalrefueling = dayData[dayData.length - 1]?.totalrefueling;
    const totalrefueling =
      secondtotalrefueling - firsttotalrefueling >= 0
        ? secondtotalrefueling - firsttotalrefueling
        : secondtotalrefueling;

    // totalpilferage functionality
    const firsttotalpilferage = dayData[0]?.totalpilferage;
    const secondtotalpilferage = dayData[dayData.length - 1]?.totalpilferage;
    const totalpilferage =
      secondtotalpilferage - firsttotalpilferage >= 0
        ? secondtotalpilferage - firsttotalpilferage
        : secondtotalpilferage;

    // totalGensetRunTime functionality
    const firsttotalGensetRunTime = dayData[0]?.totalGensetRunTime;
    const secondtotalGensetRunTime =
      dayData[dayData.length - 1]?.totalGensetRunTime;
    const totalGensetRunTime =
      secondtotalGensetRunTime - firsttotalGensetRunTime >= 0
        ? secondtotalGensetRunTime - firsttotalGensetRunTime
        : secondtotalGensetRunTime;

    // Gen_Status functionality
    let Gen_Status = 0;
    let Gen_StatusPreviousValue = null;
    for (let i = 0; i < dayData.length; i++) {
      const currentValue = dayData[i].Gen_Status;
      if (Gen_StatusPreviousValue === "0" && currentValue === "1") {
        Gen_Status++;
      }
      Gen_StatusPreviousValue = currentValue;
    }

    // wapdaStatus functionality
    let wapdaStatus = 0;
    let wapdaStatusPreviousValue = null;
    for (let i = 0; i < dayData.length; i++) {
      const currentValue = dayData[i].wapdaStatus;
      if (wapdaStatusPreviousValue === "0" && currentValue === "1") {
        wapdaStatus++;
      }
      wapdaStatusPreviousValue = currentValue;
    }

    const genTimeConvertToHour = parseInt(totalGensetRunTime) / 60;
    let averageFuel = totalfuelconsumed / genTimeConvertToHour;

    averageFuel = averageFuel >= 0 ? parseFloat(averageFuel) : 0;

    const count = dayData.length;
    return {
      device_ID: dayData[0]?.Device_ID,
      datetime: dayData[0]?.datetime,
      totalfuelconsumed,
      totalrefueling,
      totalpilferage,
      totalGensetRunTime,
      Gen_Status,
      wapdaStatus,
      averageFuel: totalGensetRunTime !== 0 ? averageFuel : 0,
      updatedAt: date,
      count,
      machineId: dayData[0]?.machineId,
      title: dayData[0].title,
      availableFuel,
    };
  });

  let totalfuelconsumedCalculation = 0;
  for (let i = 0; i < array?.length; i += 1) {
    const totalfuelconsumed = array[i]?.totalfuelconsumed;
    totalfuelconsumedCalculation += parseInt(totalfuelconsumed);
  }

  let totalrefuelingCalculation = 0;
  for (let i = 0; i < array?.length; i += 1) {
    const totalrefueling = array[i]?.totalrefueling;
    totalrefuelingCalculation += parseInt(totalrefueling);
  }

  let totalpilferageCalculation = 0;
  for (let i = 0; i < array?.length; i += 1) {
    const totalpilferage = array[i]?.totalpilferage;
    totalpilferageCalculation += parseInt(totalpilferage);
  }

  let totalGensetRunTimeCalculation = 0;
  for (let i = 0; i < array?.length; i += 1) {
    const totalGensetRunTime = array[i]?.totalGensetRunTime;
    totalGensetRunTimeCalculation += parseInt(totalGensetRunTime);
  }

  let Gen_StatusCalculation = 0;
  for (let i = 0; i < array?.length; i += 1) {
    const Gen_Status = array[i]?.Gen_Status;
    Gen_StatusCalculation += parseInt(Gen_Status);
  }

  let wapdaStatusCalculation = 0;
  for (let i = 0; i < array?.length; i += 1) {
    const wapdaStatus = array[i]?.wapdaStatus;
    wapdaStatusCalculation += parseInt(wapdaStatus);
  }

  const reportformat = `IDC_${buildingName}_branches_${departmentName}_Genset_350KVA_Daily_Report_${date?.startDate}_${date?.endDate}`;
  const reportLocation = `${buildingName}>${departmentName}>${machineName} (${ar[0]?.Device_ID})>Daily Report (${date.startDate} ~ ${date.endDate})`;
  const gensetHeading = "GENSET Health Monitoring System";
  const pdfbtn = () => {
    var doc = new jsPDF("landscape", "pt");
    var content = document.getElementById("reportid");

    const margin = 20;
    // const pageWidth = doc.internal.pageSize.getWidth() +20;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const contentWidth = content.scrollWidth;
    const scaleFactor = (pageWidth - 2 * margin) / contentWidth;
    const baseFontSize = 12;
    const responsiveFontSize = baseFontSize * scaleFactor;

    doc.html(content, {
      x: 5,
      y: margin,
      html2canvas: {
        scale: scaleFactor, // Render at the natural size of the content
      },

      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();

        const logo = idcLogo;
        const logoWidth = 80;
        const logoHeight = 40;

        doc.setFontSize(Math.max(8, Math.min(20, responsiveFontSize)));

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);

          doc.addImage(logo, "PNG", margin, margin, logoWidth, logoHeight);
          const gensetText = gensetHeading;
          const gensetTextWidth = doc.getTextWidth(gensetText);
          doc.text(
            gensetText,
            (pageWidth - gensetTextWidth) / 2,
            margin + 50 / 2
          );

          const reportTextWidth = doc.getTextWidth(reportformat);
          doc.text(reportLocation, (pageWidth - reportTextWidth) / 2, margin);

          doc.text(
            "Page " + String(i) + " of " + String(pageCount),
            pageWidth - margin,
            pageHeight - margin,
            { align: "right" }
          );
        }

        doc.save(reportformat);

        return pageCount;
      },
      margin: [margin, margin, margin, margin],
    });
  };
  const close = () => {
    navigate(-1);
  };

  return (
    <>
      {loading && (
        <>
          <Spin size="large" fullscreen={true} />
        </>
      )}
      <div className="reportmain">
        <div className="headleft">
          <BsArrowLeftShort
            className="icon cursor"
            onClick={() => navigate(-1)}
          />
          <div className="heading">{reportLocation}</div>
        </div>
        <div className="report" id="reportid">
          <div className="date"></div>
          <div className="reportsMainContainer">
            <>
              {array[array?.length - 1]?.datetime ? (
                <table className="tablemain" id="tablee">
                  <thead className="tablehead">
                    <tr>
                      <th colSpan="15" className="tableh header-title">
                        {reportLocation}
                      </th>
                    </tr>
                    <tr>
                      {array[array?.length - 1]?.datetime && (
                        <th className="tableh" rowSpan="2">
                          Date
                        </th>
                      )}
                      <th className="tableh" colSpan="11">
                        {array[0]?.title}
                      </th>
                    </tr>
                    {array[array?.length - 1]?.datetime && (
                      <tr className="tablerow">
                        <th className="tableh">Total Fuel Consumed</th>
                        <th className="tableh">Total Fuel Fill</th>
                        <th className="tableh">Total Pilferage</th>
                        <th className="tableh">Total Genset Run Time</th>
                        <th className="tableh">Gen Status</th>
                        <th className="tableh">Wapda Status</th>
                        <th className="tableh">Fuel Average</th>
                        <th className="tableh">Available Fuel</th>
                      </tr>
                    )}
                  </thead>
                  <tbody className="tablebody">
                    {array?.map((data, i, newArray) => {
                      let previousTotal =
                        i > 0 ? newArray[i - 1]?.totalizer : 0;
                      let difference = data?.totalizer - previousTotal;
                      return (
                        <tr className="tablerow">
                          {data?.datetime && (
                            <td
                              className="tabled datelink"
                              onClick={async () => {
                                setLoading(true);
                                let payload = {
                                  machineId: data?.machineId,
                                  date: data?.datetime,
                                };
                                let res = await GetDeviceHourlyReportData(
                                  payload
                                );
                                if (res?.error != null) {
                                  setLoading(false);
                                  toast.error(res.error);
                                } else {
                                  setLoading(false);
                                  navigate("/dev/reportHour", {
                                    state: {
                                      date: moment(data?.datetime).format(
                                        "DD-MM-YYYY"
                                      ),
                                      filterDateHour: res?.data?.result,
                                      machineName: machineName,
                                      buildingName: buildingName,
                                      departmentName: departmentName,
                                    },
                                  });
                                }
                              }}
                            >
                              {moment(data?.datetime).format("DD-MM-YYYY")}
                            </td>
                          )}
                          {data?.datetime && (
                            <td className="tabled">
                              {parseFloat(data?.totalfuelconsumed).toFixed(1)}
                            </td>
                          )}

                          {data?.datetime && (
                            <td className="tabled">
                              {parseFloat(data?.totalrefueling).toFixed(1)}
                            </td>
                          )}

                          {data?.datetime && (
                            <td className="tabled">
                              {parseFloat(data?.totalpilferage).toFixed(1)}
                            </td>
                          )}

                          {data?.datetime && (
                            <td className="tabled">
                              {convertMinutesToHoursMinutes(
                                data?.totalGensetRunTime
                              )}
                            </td>
                          )}

                          {data?.datetime && (
                            <td className="tabled">{data?.Gen_Status}</td>
                          )}

                          {data?.datetime && (
                            <td className="tabled">{data?.wapdaStatus}</td>
                          )}

                          {data?.datetime && (
                            <td className="tabled">
                              {parseFloat(data?.averageFuel).toFixed(1)}
                            </td>
                          )}

                          {data?.datetime && (
                            <td className="tabled">
                              {parseFloat(data?.availableFuel).toFixed(1)}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                    <tr className="tablerow">
                      {array?.[array?.length - 1]?.datetime && (
                        <th className="tableh tablehead">Total</th>
                      )}
                      <td className="tableh tablehead">
                        {parseFloat(totalfuelconsumedCalculation).toFixed(1)}
                      </td>
                      <td className="tableh tablehead">
                        {parseFloat(totalrefuelingCalculation).toFixed(1)}
                      </td>
                      <td className="tableh tablehead">
                        {parseFloat(totalpilferageCalculation).toFixed(1)}
                      </td>
                      <td className="tableh tablehead">
                        {convertMinutesToHoursMinutes(
                          totalGensetRunTimeCalculation
                        )}
                      </td>
                      <td className="tableh tablehead">
                        {parseInt(Gen_StatusCalculation)}
                      </td>
                      <td className="tableh tablehead">
                        {parseInt(wapdaStatusCalculation)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                "No Data Available"
              )}
            </>
          </div>
        </div>

        {array?.length > 1 && (
          <>
            <div className="areaChartSteamFlow">
              <AvailableFuelChart
                labels={array?.map((time) =>
                  moment(time?.datetime).format("DD |HH:mm")
                )}
                availableFuel={array?.map((data) =>
                  parseInt(data?.availableFuel)
                )}
                labelname1={"Avalable Fuel"}
              />
            </div>
            <div className="areaChartSteamFlow">
              <AreaChart1
                labels={array?.map((time) =>
                  moment(time?.datetime).format("DD |HH:mm")
                )}
                fuelFill={array?.map((data) =>
                  parseFloat(data?.totalrefueling).toFixed(1)
                )}
                labelname1={"Fuel Fill"}
                fuelConsumed={array?.map((data) =>
                  parseFloat(data?.totalfuelconsumed).toFixed(1)
                )}
                labelname2={"Fuel Consumed"}
                pilferage={array?.map((data) =>
                  parseFloat(data?.totalpilferage).toFixed(1)
                )}
                labelname3={"Pilferage"}
              />
            </div>
          </>
        )}
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};
export default ReportMachine;
